import { LearningActivityType, LearningNuggetProgressStatus } from '../../model/model';
import { mapLearningActivityCollection } from '../learning-activities/reducer';
import * as Types from './types';

const initialState = {
  loading: false,
  allLearningNuggets: [],
  filteredLearningNuggets: null,
  groupedLearningNuggets: [],
  groupedLoading: false,
  assignedLearningNuggets: [],
  detailLearningNugget: null,
  error: null,
  learningNuggetsAssignmentResults: [],
  filterInput: null,
  traceId: null
};

export const mapLearningNuggetContent = learningNuggetContent => {
  return {
    _type: LearningActivityType.Nugget,
    id: learningNuggetContent.id,
    learningNuggetId: learningNuggetContent.learningNuggetId,
    name: learningNuggetContent.content.title,
    introduction:
      learningNuggetContent.content.introduction_text || learningNuggetContent.content.info_text,
    infoText: learningNuggetContent.content.info_text,
    mainCategory:
      typeof learningNuggetContent.content.main_category === 'string'
        ? learningNuggetContent.content.main_category
        : learningNuggetContent.content.main_category[0],
    focusAreas: learningNuggetContent.content.focus_areas,
    type: learningNuggetContent.content.general_type,
    provider: learningNuggetContent.content.provider,
    nuggetTypeTitle: learningNuggetContent.content.main_category,
    imageHeader:
      learningNuggetContent.content.panorama_image?.filename ||
      learningNuggetContent.content.main_image?.filename,
    content: learningNuggetContent.content.body,
    locale: learningNuggetContent.locale,
    progress: learningNuggetContent.progress,
    rating: learningNuggetContent.ratingLearningNuggetEvent?.rating,
    feedbackMessage: learningNuggetContent.ratingLearningNuggetEvent?.feedbackMessage,
    assignedLearningNuggets: learningNuggetContent?.assignedLearningNuggets || [],
    likes: learningNuggetContent?.nonNegativeRatingCount || 0,
    next: learningNuggetContent.next,
    collections: learningNuggetContent.collections
      ? learningNuggetContent.collections.map(col => mapLearningActivityCollection(col))
      : learningNuggetContent.collections
  };
};

const mapAssignedLearningNuggets = assignedLearningNugget => {
  return {
    assignedLearningNuggetId: assignedLearningNugget.id,
    learningNuggetId: assignedLearningNugget.learningNugget.id,
    assignedBy: assignedLearningNugget.assignedBy,
    assignedTo: assignedLearningNugget.assignedTo,
    status: assignedLearningNugget.status,
    creationDate: assignedLearningNugget.createdAt,
    journeyId: assignedLearningNugget.journeyId,
    ...mapLearningNuggetContent(assignedLearningNugget.learningNugget)
  };
};

const sortByCreationDate = (a, b) => new Date(b.creationDate) - new Date(a.creationDate);

export const learningNuggetsReducer = (state = initialState, action) => {
  switch (action.type) {
    case Types.ASSIGNED_LEARNING_NUGGETS_GET: {
      return {
        ...state,
        loading: false,
        assignedLearningNuggets: action.payload
          .map(mapAssignedLearningNuggets)
          .sort(sortByCreationDate),
        error: null
      };
    }
    case Types.ASSIGNED_LEARNING_NUGGETS_DELETE: {
      return {
        ...state,
        loading: false,
        assignedLearningNuggets: state.assignedLearningNuggets.filter(
          assignedLearningNugget =>
            assignedLearningNugget.assignedLearningNuggetId !== action.payload
        ),
        error: null
      };
    }
    case Types.ASSIGNED_LEARNING_NUGGETS_COMPLETE: {
      return {
        ...state,
        loading: false,
        assignedLearningNuggets: state.assignedLearningNuggets.map(assignedLearningNugget =>
          assignedLearningNugget.assignedLearningNuggetId === action.payload
            ? { ...assignedLearningNugget, status: 'DONE' }
            : assignedLearningNugget
        ),
        error: null
      };
    }
    case Types.LEARNING_NUGGETS_LOADING: {
      return {
        ...state,
        loading: true
      };
    }
    case Types.LEARNING_NUGGETS_ERROR: {
      return {
        ...state,
        loading: false,
        error: action.payload
      };
    }
    case Types.LEARNING_NUGGETS_ASSIGNMENT_RESULTS: {
      return {
        ...state,
        loading: false,
        learningNuggetsAssignmentResults: action.payload
          ? [...state.learningNuggetsAssignmentResults, action.payload]
          : []
      };
    }
    case Types.LEARNING_NUGGET_MARK_AS_COMPLETED: {
      return {
        ...state,
        loading: false,
        detailLearningNugget: {
          ...state.detailLearningNugget,
          progress: { status: LearningNuggetProgressStatus.DONE }
        }
      };
    }
    case Types.LEARNING_NUGGET_PDF_COMPLETED: {
      return {
        ...state,
        loading: false,
        detailLearningNugget: {
          ...state.detailLearningNugget,
          progress: { status: LearningNuggetProgressStatus.DONE }
        }
      };
    }
    case Types.LEARNING_NUGGET_SEND_RATING: {
      return {
        ...state,
        loading: false,
        detailLearningNugget: {
          ...state.detailLearningNugget,
          rating: action.payload.rating,
          feedbackMessage: action.payload.feedbackMessage
        }
      };
    }
    case Types.LEARNING_NUGGET_OPENED: {
      return {
        ...state,
        loading: false
      };
    }
    case Types.LEARNING_NUGGET_PDF_OPENED: {
      return {
        ...state,
        loading: false
      };
    }
    case Types.CLEAR_LEARNING_NUGGETS_WITH_FILTERS: {
      return {
        ...state,
        filteredLearningNuggets: null,
        error: null
      };
    }
    case Types.LEARNING_NUGGETS_GET_SINGLE: {
      return {
        ...state,
        loading: false,
        detailLearningNugget: mapLearningNuggetContent(action.payload),
        error: null
      };
    }
    case Types.CLEAR_LEARNING_NUGGETS_SINGLE: {
      return {
        ...state,
        detailLearningNugget: null,
        traceId: null,
        error: null
      };
    }
    default:
      return state;
  }
};
